<template>
  <div class="page-load"></div>
  <!-- <Hero
    heroTitle="Put your brand in front of the right people"
    heroDesc="If you are building a B2B or B2C company. You can reach the right people. CareerBuddy’s media channels are trusted by millennial and Gen-Z professionals across Africa"
    heroBtnText="Get Started"
    :heroImg="Hero5"
  /> -->

  <HeroGallery :images="images">
    <template #title> Build high performing teams with handpicked world-class talent </template>
    <template #description>
      We are a 360 solution to find, hire, onboard, and retain
      <br class="hidden md:inline-block" />
      top 1% highly skilled African talents
    </template>
  </HeroGallery>

  <Customers />

  <div class="relative bg-white py-16 md:py-24 overflow-hidden">
    <div class="absolute inset-0 bg-[radial-gradient(circle_at_top,_var(--tw-gradient-stops))] from-yellow-400/5 to-transparent"></div>
    <div class="container relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="text-center max-w-3xl mx-auto mb-16">
        <span class="text-brand-black/60 text-base font-medium mb-4 block">Trusted by Industry Leaders</span>
        <h2 class="text-[32px] md:text-[50px] font-semibold text-brand-black leading-[1.1] mb-6">Our Clients</h2>
        <div class="w-20 h-1 bg-brand-black mx-auto"></div>
      </div>

      <div class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 md:gap-12">
        <a
          v-for="(logo, index) in clientLogos"
          :key="index"
          :href="logo.link"
          target="_blank"
          rel="noopener noreferrer"
          class="group relative flex items-center justify-center p-4 md:p-6 rounded-xl hover:bg-gray-50 transition-all duration-300"
        >
          <img :src="logo.src" :class="logo.class" class="object-contain transition-all duration-300 group-hover:scale-105" :alt="logo.alt || ''" />
        </a>
      </div>

      <div class="mt-16 text-center">
        <p class="text-base md:text-lg text-gray-600 max-w-2xl mx-auto">
          Join our growing list of satisfied clients who trust CareerBuddy to reach Africa's top talent
        </p>
      </div>
    </div>
  </div>

  <div class="relative bg-light-yellow py-20 md:py-32">
    <div class="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-yellow-400/5 to-transparent"></div>
    <div class="container relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="text-center max-w-3xl mx-auto mb-20">
        <span class="text-brand-black/60 text-base font-medium mb-4 block tracking-wide">Reach Your Target Audience</span>
        <h2 class="text-[24px] md:text-[32px] text-brand-black leading-[1.1] font-semibold mb-6">Our Media Brands</h2>
        <div class="w-20 h-1 bg-brand-black mx-auto"></div>
      </div>

      <div class="space-y-24">
        <!-- CareerBuddy Brand -->
        <div class="group flex flex-col lg:flex-row gap-12 items-center">
          <div class="w-full lg:w-1/2">
            <img
              src="@/assets/images/choice2.png"
              class="w-full rounded-3xl shadow-lg transition-all duration-300 group-hover:shadow-xl"
              alt="CareerBuddy Team"
              loading="lazy"
            />
          </div>
          <div class="w-full lg:w-1/2 lg:pl-8">
            <div class="bg-white rounded-3xl p-8 md:p-12 shadow-sm transition-all duration-300 hover:shadow-md">
              <h3 class="text-xl md:text-2xl font-semibold text-brand-black mb-6">CareerBuddy</h3>
              <p class="text-base md:text-lg text-gray-700 leading-relaxed mb-8">
                Careerbuddy's digital community provides relatable & engaging content to a community of over 70k young professionals to help them grow
                and thrive in their careers. We curate content that offers our audience access to opportunities and resources to help them build a
                satisfying career and life.
              </p>

              <div class="space-y-6">
                <div>
                  <h4 class="text-lg md:text-xl font-semibold text-brand-black mb-4">Social Media</h4>
                  <div class="flex flex-wrap gap-3">
                    <a
                      href="https://www.instagram.com/careerbuddyhq/"
                      target="_blank"
                      class="inline-flex items-center px-6 py-2.5 rounded-xl border border-brand-black bg-white hover:bg-brand-black hover:text-white transition-colors duration-300"
                    >
                      <svg class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                        <path
                          d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069z"
                        />
                      </svg>
                      Instagram
                    </a>
                  </div>
                </div>

                <div>
                  <h4 class="text-lg md:text-xl font-semibold text-brand-black mb-4">Our Newsletters</h4>
                  <div class="flex flex-wrap gap-3">
                    <a
                      href="https://salesmogul.substack.com/"
                      target="_blank"
                      class="inline-flex items-center px-6 py-2.5 rounded-xl border border-brand-black bg-white hover:bg-brand-black hover:text-white transition-colors duration-300"
                    >
                      <svg class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                        <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                      </svg>
                      Sales Newsletter
                    </a>
                    <a
                      href="https://cocreatebycareerbuddy.substack.com/"
                      target="_blank"
                      class="inline-flex items-center px-6 py-2.5 rounded-xl border border-brand-black bg-white hover:bg-brand-black hover:text-white transition-colors duration-300"
                    >
                      <svg class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                        <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                      </svg>
                      Tech & Marketing
                    </a>
                    <a
                      href="https://workshiftbycareerbuddy.substack.com/"
                      target="_blank"
                      class="inline-flex items-center px-6 py-2.5 rounded-xl border border-brand-black bg-white hover:bg-brand-black hover:text-white transition-colors duration-300"
                    >
                      <svg class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                        <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                      </svg>
                      Workshift
                    </a>
                    <a
                      href="https://workie.substack.com/"
                      target="_blank"
                      class="inline-flex items-center px-6 py-2.5 rounded-xl border border-brand-black bg-white hover:bg-brand-black hover:text-white transition-colors duration-300"
                    >
                      <svg class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                        <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                      </svg>
                      Workie
                    </a>
                    <a
                      href="https://newlocal.beehiiv.com/"
                      target="_blank"
                      class="inline-flex items-center px-6 py-2.5 rounded-xl border border-brand-black bg-white hover:bg-brand-black hover:text-white transition-colors duration-300"
                    >
                      <svg class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                        <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                      </svg>
                      NewLocal
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- WorkHERholic Brand -->
        <div class="group flex flex-col lg:flex-row-reverse gap-12 items-center">
          <div class="w-full lg:w-1/2">
            <img
              src="@/assets/images/choice3.png"
              class="w-full rounded-3xl shadow-lg transition-all duration-300 group-hover:shadow-xl"
              alt="WorkHERholic Community"
              loading="lazy"
            />
          </div>
          <div class="w-full lg:w-1/2 lg:pr-8">
            <div class="bg-white rounded-3xl p-8 md:p-12 shadow-sm transition-all duration-300 hover:shadow-md">
              <h3 class="text-xl md:text-2xl font-semibold text-brand-black mb-6">WorkHERholic</h3>
              <p class="text-base md:text-lg text-gray-700 leading-relaxed mb-8">
                At WorkHerHolic, we drive conversation and inspire action for our community of African millennial and Gen-Z women who are daily
                crushing goals and looking for opportunities to connect, learn, and thrive! We are perfect for the African woman who wants to get
                ahead in her career and have fun while at it.
              </p>

              <div class="space-y-4">
                <h4 class="text-lg md:text-xl font-semibold text-brand-black">Properties</h4>
                <div class="flex flex-wrap gap-3">
                  <a
                    href="https://www.instagram.com/workherholic/"
                    target="_blank"
                    class="inline-flex items-center px-6 py-2.5 rounded-xl border border-brand-black bg-white hover:bg-brand-black hover:text-white transition-colors duration-300"
                  >
                    <svg class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                      <path
                        d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069z"
                      />
                    </svg>
                    Instagram
                  </a>
                  <a
                    href="https://workherholic.substack.com"
                    target="_blank"
                    class="inline-flex items-center px-6 py-2.5 rounded-xl border border-brand-black bg-white hover:bg-brand-black hover:text-white transition-colors duration-300"
                  >
                    <svg class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                      <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                    </svg>
                    Daily Newsletter
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <AudienceBreakdown />

  <div class="relative bg-white">
    <div class="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-gray-50 to-transparent"></div>
    <div class="container relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="py-20 md:py-32 text-center">
        <div class="max-w-4xl mx-auto">
          <h2 class="text-[24px] md:text-[32px] text-brand-black font-semibold leading-[1.1] mb-8">Ready to work together?</h2>

          <p class="text-base md:text-[20px] leading-[1.4] text-brand-black/60 mb-12 mx-auto max-w-3xl">
            Partner with CareerBuddy today, and let's craft a narrative that propels your
            <br class="hidden md:inline-block" />
            organization into the hearts and minds of Africa's most coveted professionals
          </p>

          <div class="mt-10">
            <a
              href="/contact"
              class="inline-flex items-center px-8 md:px-12 py-4 text-[16px] md:text-[18px] font-medium rounded-2xl bg-brand-black text-white transition-all duration-300 hover:shadow-lg hover:scale-[1.02] group"
            >
              Start Hiring
              <svg
                class="w-5 h-5 ml-2 transition-transform duration-300 group-hover:translate-x-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <CreateAccount />
</template>

<script setup>
import { onMounted, ref } from 'vue';
import Hero from '@/components/partials/Hero.vue';
import CreateAccount from '@/components/partials/CreateAccount.vue';
import Customers from '@/components/partials/Customers.vue';
import Hero5 from '@/assets/images/hero5.png';
import AudienceBreakdown from '@/components/common/AudienceBreakdown.vue';
import HeroGallery from '@/components/partials/HeroGallery.vue';
import { useMeta } from '@/utils/meta';

const clientLogos = [
  { src: require('@/assets/images/zikoko.png'), class: 'w-[70px] md:w-[150px]', alt: 'Zikoko', link: 'https://www.zikoko.com/' },
  { src: require('@/assets/images/bolt.png'), class: 'w-[70px] md:w-[98px]', alt: 'Bolt', link: 'https://bolt.eu/en-ng/' },
  { src: require('@/assets/images/skillpaddy.png'), class: 'w-[70px] md:w-[130px]', alt: 'SkillPaddy', link: 'https://www.skillpaddy.com/' },
  { src: require('@/assets/images/earnipay.png'), class: 'w-[70px] md:w-[150px]', alt: 'Earnipay', link: 'https://www.earnipay.com/' },
  { src: require('@/assets/images/apt.png'), class: 'w-[70px] md:w-[150px]', alt: 'Team Apt', link: 'https://www.teamaptltd.com/' },
  { src: require('@/assets/images/yalo.png'), class: 'w-[70px] md:w-[124px]', alt: 'Yalo', link: 'https://www.yalo.ng/' },
  { src: require('@/assets/images/mystash.png'), class: 'w-[70px] md:w-[180px]', alt: 'MyStash', link: 'https://www.mystashapp.com/' },
  { src: require('@/assets/images/altschool.png'), class: 'w-[70px] md:w-[130px]', alt: 'AltSchool', link: 'https://www.altschoolafrica.com/' },
  { src: require('@/assets/images/ajocard.png'), class: 'w-[70px] md:w-[200px]', alt: 'AjoCard', link: 'https://www.ajocard.com' },
  { src: require('@/assets/images/shuttlers.png'), class: 'w-[70px] md:w-[174px]', alt: 'Shuttlers', link: 'https://www.shuttlers.co/' },
  { src: require('@/assets/images/alx.png'), class: 'w-[70px] md:w-[129px]', alt: 'ALX', link: 'https://www.alxafrica.com/' },
  { src: require('@/assets/images/zilla.png'), class: 'w-[70px] md:w-[129px]', alt: 'Zilla', link: 'https://www.usezilla.ng/' },
  { src: require('@/assets/images/pade.png'), class: 'w-[70px] md:w-[157px]', alt: 'Pade', link: 'https://www.padehr.ng/' },
  { src: require('@/assets/images/sla.png'), class: 'w-[70px] md:w-[160px]', alt: 'SLA', link: 'https://www.sheleadsafrica.org/' },
  { src: require('@/assets/images/techconnect.png'), class: 'w-[70px] md:w-[180px]', alt: 'TechConnect', link: 'https://www.techconnect.org/' },
  { src: require('@/assets/images/moniepoint.png'), class: 'w-[70px] md:w-[150px]', alt: 'Moniepoint', link: 'https://www.moniepoint.com/' },
];

const images = {
  one: require('@/assets/images/h1.jpeg'),
  two: require('@/assets/images/h5.jpeg'),
  three: require('@/assets/images/h2.jpeg'),
  four: require('@/assets/images/h12.jpeg'),
  five: require('@/assets/images/h3.jpeg'),
};

onMounted(() => {
  document.querySelector('.page-load')?.scrollIntoView();
});

useMeta();
</script>
