<template>
  <div class="py-20 md:py-32 bg-white overflow-hidden">
    <div class="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="text-center mb-16">
        <h2 class="text-[24px] md:text-[32px] font-semibold text-brand-black mb-6">Our Audience Breakdown</h2>
        <div class="w-20 h-1 bg-brand-black mx-auto"></div>
      </div>

      <!-- Stats Grid -->
      <div class="grid grid-cols-2 lg:grid-cols-4 gap-8 md:gap-12 mb-20">
        <div v-for="(stat, index) in stats" :key="index" 
             class="group relative flex flex-col items-center p-6 rounded-2xl bg-white hover:bg-gray-50 transition-all duration-300"
             :class="{'border-r border-gray-100': index < stats.length - 1}"
        >
          <div class="mb-4 relative">
            <span class="text-[32px] md:text-[48px] font-bold text-brand-black">{{ stat.value }}</span>
            <div class="absolute -inset-1 scale-100 opacity-0 group-hover:opacity-100 transition-all duration-200 rounded-md bg-brand-primary/5"></div>
          </div>
          <p class="text-sm md:text-base text-gray-600 text-center">{{ stat.description }}</p>
        </div>
      </div>

      <!-- Features Grid -->
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 md:gap-12">
        <div v-for="(feature, index) in features" :key="index" 
             class="group p-6 rounded-2xl bg-white hover:bg-gray-50 transition-all duration-300 border border-gray-100"
        >
          <div class="mb-6">
            <component :is="feature.icon" class="w-12 h-12 text-brand-black" />
          </div>
          <h3 class="text-lg md:text-xl font-semibold text-brand-black mb-3">{{ feature.title }}</h3>
          <p class="text-sm md:text-base text-gray-600 leading-relaxed">{{ feature.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const stats = ref([
  {
    value: '1.3M+',
    description: 'Average Monthly Web & Social Traffic'
  },
  {
    value: '4.5M+',
    description: 'Average Monthly Impressions & Organic Reach on Instagram'
  },
  {
    value: '250k+',
    description: 'Newsletter Subscribers across platforms'
  },
  {
    value: '10M+',
    description: 'Cross Platform Reach'
  }
]);

const features = ref([
  {
    icon: 'UserGroupIcon',
    title: 'We Attract Captive Audiences',
    description: 'Reach highly engaged millennial and Gen Z audiences across our products and platforms'
  },
  {
    icon: 'SparklesIcon',
    title: 'Our Campaigns Are High Impact & Memorable',
    description: 'We create authentic and creative campaigns that cannot be replicated by any other publisher'
  },
  {
    icon: 'ClockIcon',
    title: 'Our Branded Content Is Always Relevant & Timely',
    description: 'We seamlessly incorporate our clients into organic, trending moments for optimal engagement'
  },
  {
    icon: 'BoltIcon',
    title: "We're Flexible & Fast",
    description: 'Have a quick turnaround? We can develop and launch custom programming within 24hrs'
  }
]);
</script>

<script>
import { UserGroupIcon, SparklesIcon, ClockIcon, BoltIcon } from '@heroicons/vue/24/outline';

export default {
  components: {
    UserGroupIcon,
    SparklesIcon,
    ClockIcon,
    BoltIcon
  }
};
</script>
